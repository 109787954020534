<template>
  <div>
    <div style="margin-top: 10px; margin-bottom: 0" class="divider">
      <span style="color: #666; padding-left: 5px"
        ><v-icon style="color: #666 !important">pie_chart</v-icon> Daily
        Attendance Inspection ({{ nd.format("YYYY-MM-DD") }})
        </span>
        <span class="dashboard_refresh_button">
            <v-icon medium  @click="getAttendanceChartData(true)" v-if=" !refresh_status">autorenew</v-icon>
            <v-progress-circular indeterminate color="#ccc" v-else :size="20"></v-progress-circular>
        </span>
    </div>
    <!-- <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart> -->
    <apexchart
      type="area"
      width="100%"
      height="300"
      :options="attendanceChartOptions"
      :series="attendanceSeries"
    ></apexchart>

  </div>
</template>

<script>
const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();

export default {
  data() {
    return {
      attendanceChartOptions: {},
      attendanceSeries: [],
      nd,
      refresh_status: false,
    };
  },
  mounted() {
    // this.getAttendanceChartData();
  },
  methods: {
    getAttendanceChartData(status=false) {
      return [];
      if (localStorage.getItem("dashboard") !== null && status===false) {
        const attendance_dashboard = JSON.parse (window.localStorage.getItem('dashboard'))
        const attendance_chart_data = attendance_dashboard.daily_attendance_data
        let presentee = [];
          let absentees = [];
          let leave = [];
          let allData = {};
          attendance_chart_data.data.map((rd) => {
            attendance_chart_data.grades.map((g) => {
              if (rd.name === g.toString()) {
                allData[g.toString()] = {
                  leave: rd.leave,
                  absent: rd.absent,
                  present: rd.present,
                };
              }
            });
          });
          attendance_chart_data.grades.map((grade) => {
            if (!["", undefined, null].includes(allData[grade])) {
              presentee.push(allData[grade].present);
              absentees.push(allData[grade].absent);
              leave.push(allData[grade].leave);
            } else {
              presentee.push("0");
              absentees.push("0");
              leave.push("0");
            }
          });
         
         
          this.attendanceChartOption(attendance_chart_data.grades)
          this.attendanceData(presentee,absentees,leave)

      } else {
        this.$rest.get("api/report/academic/today-attendance").then((res) => {
          this.refresh_status = true
          if (res.data.length === 0) return;
          let presentee = [];
          let absentees = [];
          let leave = [];
          let allData = {};
          res.data.data.map((rd) => {
            res.data.grades.map((g) => {
              if (rd.name === g.toString()) {
                allData[g.toString()] = {
                  leave: rd.leave,
                  absent: rd.absent,
                  present: rd.present,
                };
              }
            });
          });
          res.data.grades.map((grade) => {
            if (!["", undefined, null].includes(allData[grade])) {
              presentee.push(allData[grade].present);
              absentees.push(allData[grade].absent);
              leave.push(allData[grade].leave);
            } else {
              presentee.push("0");
              absentees.push("0");
              leave.push("0");
            }
          });
          let old_value =  JSON.parse(window.localStorage.getItem('dashboard'))
          let daily_attendance_data = res.data
          localStorage.removeItem("dashboard");
          let daily_attendance = {
              daily_attendance_data,
              ...old_value

          }
          window.localStorage.setItem(
            "dashboard",
            JSON.stringify(daily_attendance)
          );
          if(res.status == 200){
            setTimeout(() => {
              this.refresh_status = false
            },2000);
          }
          // this.attendanceChartOptions = {
          //   colors: ["#66DA26", "#ff5252", "#FF9800"],
  
          //   chart: {
          //     type: "area",
          //     id: "daily attendance",
          //     toolbar: {
          //       show: false,
          //     },
          //   },
          //   colors: ["#00C853", "#D32F2F", "#FF6F00"],
          //   dataLabels: {
          //     enabled: false,
          //   },
          //   stroke: {
          //     curve: "smooth",
          //   },
          //   grid: {
          //     row: {
          //       colors: ["#efeeee", "transparent"], // takes an array which will be repeated on columns
          //       opacity: 0.5,
          //     },
          //   },
          //   xaxis: {
          //     categories: res.data.grades,
          //   },
          // };
          this.attendanceChartOption(res.data.grades)
          this.attendanceData(presentee,absentees,leave)
          // this.attendanceSeries = [
          //   {
          //     name: "Present",
          //     data: presentee,
          //     colors: ["red"],
          //   },
          //   {
          //     name: "Absent",
          //     data: absentees,
          //   },
          //   {
          //     name: "Leave",
          //     data: leave,
          //   },
          // ];
        });
      }
    },
    attendanceData(presentee,absentees,leave){
       this.attendanceSeries = [
            {
              name: "Present",
              data: presentee,
              colors: ["red"],
            },
            {
              name: "Absent",
              data: absentees,
            },
            {
              name: "Leave",
              data: leave,
            },
          ];
    },
    attendanceChartOption(value){
      this.attendanceChartOptions = {
        colors: ["#66DA26", "#ff5252", "#FF9800"],

        chart: {
          type: "area",
          id: "daily attendance",
          toolbar: {
            show: false,
          },
        },
        colors: ["#00C853", "#D32F2F", "#FF6F00"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          row: {
            colors: ["#efeeee", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: value,
        },
      };
    },
    
  },
};
</script>
